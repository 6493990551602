
.button {
  ::v-deep .icon {
    transition: all 0.3s;
    padding-left: 0;
  }
  &:hover {
    ::v-deep .icon {
      position: relative;
      padding-left: 15px;
    }
  }
}
