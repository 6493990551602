
.voucherbutton {
  margin-bottom: 10px;
}

.container {
  padding-top: 20px;
}

.card {
  background: white;
  box-shadow: none;
  border-radius: 20px;
  padding: 30px 15px 15px 15px;
  font-size: 0.9rem;
  height: 600px;

  &.selected {
    border: 3px solid #4189f4;
  }

  img {
    max-width: 30%;
  }

  .ul-content {
    padding: 20px 0;

    li {
      margin: 5px 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tag-chosen {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 15px;
    background-color: #4189f4;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
  }
}

.sale {
  display: flex;
  border-radius: 100%;
  background: #ff5b5b;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
